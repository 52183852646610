export default {
    common: {
        confirm: 'Confrim',
        cancel: 'Cancel',
        search: 'Search',
        submit: 'Submit',
        other: 'Other',
        default: 'DEFAULT',
        color_tag: 'COLOR TAG',
        option: 'Option',
        success: 'Success',
        error: 'Error',
        sending: 'Sending',
        send_success: 'Send Success',
        send_error: 'Send Failure',
        visits: 'VISITS',
        table: 'TABLE',
        'one-way-message': '1-way SMS',
        'two-way-message': '2-way SMS',
        'one-way-voice': '1-way Voice',
        'two-way-voice': '2-way Voice',
        broadcast: 'Broadcast',
        delete: 'Delete',
        delete_tip: 'Are you sure to delete?',
    },
    setting: {
        setting: 'Setting',
        general: 'General',
        account: 'Account',
        about: 'About',
        custom_language_label: 'Custom Language',
        logo_label: 'Restaurant Logo',
        name_label: 'Restaurant Name',
        version_label: 'Version',
        privacy_label: 'Privacy Statement',
        terms_label: 'Terms of Service',
        logout_btn: 'Logout',
        logout_tip: 'Logout current user?'
    },
    waitlist: {
        waitlist: 'Waitlist',
        now_waitlist: 'Join real time',
        callahead: 'Callahead',
        all: 'All',
        no_show: 'NO SHOW',
        check_in: 'Check in',
        checked_in: 'Checked in',
        waiting: 'Waiting',
        notify: 'Notify',
        seat: 'Seat',
        add_waitlist: 'Add Waitlist',
        guest_number: 'Guest Number',
        guest_name: 'Guest Name',
        phone_num: 'Phone Number',
        visit_note: 'Visit Notes',
        onsite_notify: 'Onsite Notify',
        table_ready: 'Table Ready',
        broadcast: 'Broadcast',
        two_way_sms: '2-way SMS',
        two_way_voice: '2-way Voice',
        resend_invite: 'Resent Invite',
        seat_with_table: 'Seat with Table',
        waitlist_detail: 'WAITLIST DETAILS',
        table_assign: 'Table Assign',
        timeline: 'TIMELINE',
        not_arrived: 'NOT ARRIVED',
        partial_arrived: 'PARTIAL ARRIVE',
        all_arrived: 'ALL ARRIVED',
        join_waitlist: 'Join waitlist',
        input_name_tip: 'Please input customer name',
        choose_people_num_tip: 'Please choose people number',
        restore: 'RESTORE',
        restore_tip: 'Restore to the original rank',
        join_time: 'Join time',
        choose_join_time_tip: 'Please choose join time',
        choose_right_join_time_tip: 'Please choose future join time',
        join_waitlist_tip: 'Are you sure to join waitlist?',
        callahead_detail: 'CALLAHEAD DETAILS',
        created_callahead: 'CREATED CALLAHEAD',
        input_phone_num_tip: 'Please input the correct phone number',
        unchecked_in: 'Undo check-in',
        left: 'Left',
        expired: 'Expired',
    },
    reserve: {
        reserve: 'Reservation',
        notify_one_way_remind: 'One-way Remind Reservation',
        notify_sms: 'SMS',
        notify_voice: 'Voice Call',
        cancel: 'CANCEL REZ',
        reserve_detail: 'RESERVATION DETAILS',
        create_reservation: 'CREATE RESERVATION',
        merchant_remark: 'Merchant Remark',
        this_reservation: 'this reservation',
        sms_sent: 'SMS SENT',
        sms_confirm: 'SMS CONFIRM',
    },
    floorplan: {
        floorplan: 'Floor Plan'
    },
};