<template>
    <div class="reserve">
        <a-row >
            <a-col :span="7" class="left_zone">
                <a-row style="padding: 0 10px;">
                    <a-col :span="2">
                        &nbsp;
                    </a-col>
                    <a-col :span="20" style="font-size: 24px; text-align: center; line-height: 30px;">
                        <h5>{{ now_month }} - {{ next_month }}</h5>
                    </a-col>
                    <a-col :span="2">
                        &nbsp;
                    </a-col>
                </a-row>
                <div class="calendar_zone">
                    <h3>{{ now_month }}</h3>
                    <a-flex wrap="wrap" style="margin: 20px 0px;">
                        <span class="item" :style="{backgroundColor: count_dict[item.date_str] && count_dict[item.date_str].unconfirmed_count > 0 ? '#fdff4e' : ''}" @click="chooseDate(now_month, item.name, true)" :class="{current: choose_now_month && item.name == now_date_number, disabled: item.disabled}" v-for="(item, index) in now_month_arr" :key="'now' + item + index">
                            {{ item.name }}
                            <div class="num" v-if="count_dict[item.date_str]">{{ count_dict[item.date_str].reservation_count }}</div>
                        </span>
                    </a-flex>
                    <h3>{{ next_month }}</h3>
                    <a-flex wrap="wrap" style="margin: 20px 0px;">
                        <span class="item" :style="{backgroundColor: count_dict[item.date_str] && count_dict[item.date_str].unconfirmed_count > 0 ? '#fdff4e' : ''}" @click="chooseDate(next_month, item.name, false)" :class="{current: !choose_now_month && item.name == now_date_number}" v-for="(item, index) in next_month_arr" :key="'next' + item + index">
                            {{ item.name }}
                            <div class="num" v-if="count_dict[item.date_str]">{{ count_dict[item.date_str].reservation_count }}</div>
                        </span>
                    </a-flex>
                </div>
            </a-col>
            <a-col :xs="{span: 24}" :lg="{span: 17}" class="right_zone">
                <div>
                    <a-row>
                        <a-col :lg="{span: 4}" :xs="{span: 8}">
                            <a-button type="primary" :danger="reserve_list.length == 0">{{ now_date_text }}</a-button>
                            <a-date-picker :disabled-date="disabledDate" :inputReadOnly="true" style="position: absolute; top: 0; left: 0;opacity: 0; width: 100%;" @change="onDateChange" v-model:value="value1" />
                        </a-col>
                        <a-col :span="8" v-if="!is_mobile">
                            <a-input-search
                                v-model:value="q"
                                :placeholder="$t('common.search')"
                                enter-button
                                @search="onSearch"
                            />
                        </a-col>
                        <a-col :lg="{span: 5, offset: 7}" :xs="{span: 5, offset: 5}">
                            <a-space>
                                <a-button aria-disabled="true" :disabled="true"> <DeleteOutlined :style="{fontSize: '18px'}"/> </a-button>
                                <a-button aria-disabled="true" :disabled="true"> <HistoryOutlined :style="{fontSize: '18px'}"/> </a-button>
                                <a-button aria-disabled="true" @click="onSearch">
                                    <Loading3QuartersOutlined v-if="!is_loading" :style="{fontSize: '18px'}"/>
                                    <LoadingOutlined v-else :style="{fontSize: '18px'}"/>
                                </a-button>
                            </a-space>
                        </a-col>
                    </a-row>
                </div>
                <div style="height: 90vh; overflow: auto;">
                    <a-list
                        class="demo-loadmore-list"
                        item-layout="horizontal"
                        :data-source="reserve_list"
                        style="margin-top: 10px;"
                    >
                        <template #renderItem="{ item }">
                            <a-list-item :style="{backgroundColor: item.rstatus > 0 ? (item.color_tags && item.color_tags.length > 0 ? item.color_tags[0].color : '#fff') : '#fdff4e'}" style="margin-top: 7px; height: 100px;">
                                <template #actions>
                                </template>
                                <a-list-item-meta @click="doShowInfoLayer(item)">
                                    <template #title>
                                        <span>{{ item.customer_name + '(' + item.people_num + ')' }}</span>
                                        <div>
                                            <PhoneOutlined />
                                            {{ item.phone_num_short }}
                                        </div>
                                        <div v-if="item.note">
                                            <AlertFilled />
                                            <span style="text-decoration: underline;">{{ item.note }}</span>
                                        </div>
                                        <div v-if="item.remark">
                                            <AlertFilled />
                                            <span style="text-decoration: underline;">{{ item.remark }}</span>
                                        </div>
                                    </template>
                                    <template #avatar>
                                        <div class="custom_big_btn" :class="{green_color1: item.table_ready_result == 1, grey_color: item.rstatus == 0, yellow_color2: (item.rstatus == 6 || item.all_arrived > 0), yellow_color1: (item.table_ready_sent == 1 && item.table_ready_result == 0)}" style="width: 120px; padding-top: 7px;">
                                            <h4 style="margin-bottom: 2px;">{{ item.reserve_time_str }}</h4>
                                            <h5 style="margin: 0px 10px;">{{ item.reserve_date_str }}</h5>
                                            <h5 style="margin-top: 2px; font-size: 11px;">
                                                <span v-if="item.rstatus == 6">{{ $t('waitlist.checked_in') }}</span>
                                                <span v-else>
                                                    <span v-if="item.all_arrived == 0">
                                                        <span v-if="item.table_ready_sent == 0">{{ item.rstatus_text }}</span>
                                                        <span v-if="item.table_ready_sent == 1 && item.table_ready_result == 0">{{ $t('reserve.sms_sent') }}</span>
                                                        <span v-if="item.table_ready_sent == 1 && item.table_ready_result > 0">{{ $t('reserve.sms_confirm') }}</span>
                                                    </span>
                                                    <span v-if="item.all_arrived == 1">
                                                        {{ $t('waitlist.all_arrived') }}
                                                    </span>
                                                    <span v-if="item.all_arrived == 2">
                                                        {{ $t('waitlist.partial_arrived') }}
                                                    </span>
                                                </span>
                                            </h5>
                                        </div>
                                    </template>
                                </a-list-item-meta>
                                <div class="custom_big_btn" v-if="is_today == 1 && item.rstatus == 1" @click="doCheckIn(item)">
                                    <SafetyCertificateOutlined class="list_icon"/>
                                    <h5 style=" margin-top: 5px;">{{ $t('waitlist.check_in') }}</h5>
                                </div>
                                <div class="custom_big_btn yellow_color2" v-if="is_today == 1 && item.rstatus == 6" @click="undoCheckIn(item)">
                                    <SafetyCertificateOutlined class="list_icon"/>
                                    <h5 style=" margin-top: 5px;">{{ $t('waitlist.unchecked_in') }}</h5>
                                </div>
                                <div class="custom_big_btn yellow_color1" v-if="item.rstatus > 0"  style="margin-left: 10px;" @click="doShowNotifyLayer(item)">
                                    <MailOutlined class="list_icon"/>
                                    <h5 style="margin-top: 5px;" v-if="item.table_ready_sent == 0">{{ $t('waitlist.notify') }}</h5>
                                    <h5 style="margin-top: 5px;" v-if="item.table_ready_sent == 1">
                                        <MessageFilled />
                                        {{ item.auto_remind_count + ' · ' + item.ready_text_time }}
                                    </h5>
                                </div>
                                <div class="custom_big_btn green_color1" v-if="(item.rstatus == 1 || item.rstatus == 6) && is_today == 1" style="margin-left: 10px;" @click="doShowSeatLayer(item)">
                                    <CoffeeOutlined class="list_icon"/>
                                    <h5 style="margin-top: 5px;">{{ $t('waitlist.seat') }}</h5>
                                </div>
                                <div class="custom_big_btn green_color1" v-if="item.rstatus ==0 " style="margin-left: 10px;" @click="doConfirm(item)">
                                    <CheckCircleOutlined class="list_icon"/>
                                    <h5 style="margin-top: 5px;">{{ $t('common.confirm') }}</h5>
                                </div>
                            </a-list-item>
                        </template>
                    </a-list>
                </div>
            </a-col>
        </a-row>
        <a-modal v-model:open="show_notify_layer" :zIndex="2000" :width="400" :title="layer_user_info.phone_num_short + ' | ' + layer_user_info.customer_name" :footer="null">
            <div style="position: relative;">
                <a-divider orientation="left">{{ $t('reserve.notify_one_way_remind') }}</a-divider>
                <div class="custom_big_btn" style="margin-left: 25px;" @click="do_send_notification('one_way_sms_remind')">
                    <CommentOutlined style="font-size: 38px; color: #000;"/>
                    <h5 style="color: #000; margin-top: 5px;">{{ $t('reserve.notify_sms') }}</h5>
                </div>
                <div class="custom_big_btn" style="margin-left: 25px;" @click="do_send_notification('one_way_voice_remind')">
                    <PhoneOutlined style="font-size: 38px; color: #000;"/>
                    <h5 style="color: #000; margin-top: 5px;">{{ $t('reserve.notify_voice') }}</h5>
                </div>
                <a-spin v-if="is_sending" size="large" style="position: absolute; top: 50%; left: 50%;">
                </a-spin>
                <div v-if="is_sending" style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; background-color: #eee; opacity: 0.5;"></div>
            </div>
        </a-modal>
        <a-modal v-model:open="show_seat_layer" :zIndex="2000" :title="layer_user_info.phone_num_short + ' | ' + layer_user_info.customer_name" width="300px" :footer="null">
            <h2 style="text-align: center; margin-top: 30px;">{{ $t('waitlist.seat') }} {{ layer_user_info.customer_name }}({{ layer_user_info.people_num }})?</h2>
            <a-input style="width: 250px; margin-top: 20px;" v-model:value="seat_table_name" :placeholder="$t('common.table')" />
            <div>
                <a-button @click="do_seat" style="width: 250px; display: inline-block; margin-top: 20px; background-color: #34af48; color: #fff;">{{ $t('waitlist.seat') }}</a-button>
            </div>
            <div>
                <a-button :disabled="true" style="width: 250px; display: inline-block; margin-top: 20px;" type="primary">{{ $t('waitlist.seat_with_table') }}</a-button>
            </div>
        </a-modal>
        <a-drawer class="user_info_zone" :zIndex="1000" @close="doHideInfoLayer" v-model:open="show_info_layer" placement="right" :width="!is_mobile ? 400 : 375" :footer="null">
            <a-skeleton v-if="false" active />
            <div class="user_info_header">
                <a-row>
                    <a-col :span="9">
                        <div class="custom_big_btn" :class="{green_color1: layer_user_info.table_ready_result == 1, grey_color: layer_user_info.rstatus == 0, yellow_color2: (layer_user_info.rstatus == 6 || layer_user_info.all_arrived > 0), yellow_color1: (layer_user_info.table_ready_sent == 1 && layer_user_info.table_ready_result == 0)}" style="padding-top: 7px; width: 120px;">
                            <h4 style="margin-bottom: 2px;">{{ layer_user_info.reserve_time_str }}</h4>
                            <h5 style="margin: 0px 10px;">{{ layer_user_info.reserve_date_str }}</h5>
                            <h5 style="margin-top: 2px;">
                                <span v-if="layer_user_info.rstatus == 6">{{ $t('waitlist.checked_in') }}</span>
                                <span v-else>
                                    <span v-if="layer_user_info.all_arrived == 0">
                                        <span v-if="layer_user_info.table_ready_sent == 0">{{ layer_user_info.rstatus_text }}</span>
                                        <span v-if="layer_user_info.table_ready_sent == 1 && layer_user_info.table_ready_result == 0">{{ $t('reserve.sms_sent') }}</span>
                                        <span v-if="layer_user_info.table_ready_sent == 1 && layer_user_info.table_ready_result > 0">{{ $t('reserve.sms_confirm') }}</span>
                                    </span>
                                    <span v-if="layer_user_info.all_arrived == 1">
                                        {{ $t('waitlist.all_arrived') }}
                                    </span>
                                    <span v-if="layer_user_info.all_arrived == 2">
                                        {{ $t('waitlist.partial_arrived') }}
                                    </span>
                                </span>
                            </h5>
                        </div>
                    </a-col>
                    <a-col :span="14">
                        <div>
                            <h4>{{ layer_user_info.people_num }} | {{ layer_user_info.customer_name }}</h4>
                            <p style="margin-bottom: 5px;">{{ layer_user_info.phone_num }}</p>
                            <span v-for="item in layer_user_info.guest_tags" v-bind:key="item.name">
                                <a-tag :color="item.color">{{ item.name }}</a-tag>
                            </span>
                        </div>
                    </a-col>
                    <a-col v-if="false">
                        <EditOutlined style="font-size: 24px; color: #000;"/>
                    </a-col>
                </a-row>
            </div>
            <a-divider />
            <div class="user_top_action">
                <a-space>
                    <div class="custom_big_btn yellow_color1" v-if="layer_user_info.rstatus > 0" @click="doShowNotifyLayer(layer_user_info)">
                        <MailOutlined style="font-size: 28px; color: #fff;"/>
                        <h5 style="margin-top: 5px;">{{ $t('waitlist.notify') }}</h5>
                    </div>
                    <div class="custom_big_btn" v-if="is_today == 1 && layer_user_info.rstatus == 1" @click="doCheckIn(layer_user_info)">
                        <SafetyCertificateOutlined style="font-size: 28px;"/>
                        <h5 style=" margin-top: 5px;">{{ $t('waitlist.check_in') }}</h5>
                    </div>
                    <div class="custom_big_btn yellow_color2" v-if="is_today == 1 && layer_user_info.rstatus == 6" @click="undoCheckIn(layer_user_info)">
                        <SafetyCertificateOutlined class="list_icon"/>
                        <h5 style=" margin-top: 5px;">{{ $t('waitlist.unchecked_in') }}</h5>
                    </div>
                    <div class="custom_big_btn green_color1" v-if="(layer_user_info.rstatus == 1 || layer_user_info.rstatus == 6) && is_today == 1" @click="doShowSeatLayer(layer_user_info)">
                        <CoffeeOutlined style="font-size: 28px;"/>
                        <h5 style="margin-top: 5px;">{{ $t('waitlist.seat') }}</h5>
                    </div>
                    <div class="custom_big_btn green_color1" v-if="layer_user_info.rstatus ==0 " style="margin-left: 10px;" @click="doConfirm(layer_user_info)">
                        <CheckCircleOutlined class="list_icon"/>
                        <h5 style="margin-top: 5px;">{{ $t('common.confirm') }}</h5>
                    </div>
                </a-space>
            </div>
            <div class="user_waitlist_data">
                <h4 style="background: #f5f5f5;line-height: 30px;">{{ $t('reserve.reserve_detail') }}</h4>
                <div>
                    <span v-if="layer_user_info.source == 'byphone'"> <PhoneOutlined /> </span>
                    <span v-if="layer_user_info.source == 'onsite'"> <GlobalOutlined /> </span>
                    <span v-if="layer_user_info.source == 'google'"> <GoogleOutlined /> </span>
                    
                    <a-button style="background-color: #f5f5f5; margin-left: 10px;" :disabled="true">
                        <template #icon>
                            <AuditOutlined />
                        </template>
                        {{ $t('waitlist.table_assign') }}
                    </a-button>
                </div>
                <div style="margin-top: 10px;">
                    <a-input-group compact>
                        <a-input v-model:value="layer_user_info.remark" style="width: calc(100% - 80px)" :placeholder="$t('reserve.merchant_remark')">
                            <template #prefix>
                                <FormOutlined />
                            </template>
                        </a-input>
                        <a-button @click="doEditRemark(layer_user_info)" type="primary">{{ $t('common.submit') }}</a-button>
                    </a-input-group>
                </div>
                <div style="margin-top: 10px;">
                    <a-button :disabled="true">+ {{ $t('common.option') }}</a-button>
                </div>
            </div>
            <div class="user_timeline">
                <h4 style="background: #f5f5f5;line-height: 30px;">{{ $t('waitlist.timeline') }}</h4>
                <a-timeline v-if="!is_loading_timeline" style="height: 140px; overflow: auto; padding-top: 5px;">
                    <a-timeline-item v-for="(item,index) in timeline_list" v-bind:key="item.title + index">{{ item.title }}</a-timeline-item>
                </a-timeline>
                <a-skeleton active v-if="is_loading_timeline" />
            </div>
            <div class="user_bottom_action">
                <a-space>
                    <div class="custom_big_btn" @click="doCancel(layer_user_info)" style="color: rgb(233, 60, 60); border-color: rgb(233, 60, 60);">
                        <EyeInvisibleOutlined style="font-size: 28px;" />
                        <h5 style="margin-top: 5px;">{{ $t('reserve.cancel') }}</h5>
                    </div>
                    <a-popover title="" v-if="is_today == 1" trigger="click" :open="show_arrive_layer">
                        <template #content>
                            <a-space>
                                <div class="custom_big_btn blue_color1" :class="{'current': layer_user_info.all_arrived == 0}" style="width: 120px;" @click="chooseArrive(0)">
                                    <MinusCircleOutlined  style="font-size: 28px;"/>
                                    <h5 style="margin-top: 5px;">{{ $t('waitlist.not_arrived') }}</h5>
                                </div>
                                <div class="custom_big_btn blue_color1" :class="{'current': layer_user_info.all_arrived == 2}" style="width: 120px;" @click="chooseArrive(2)">
                                    <UserOutlined style="font-size: 28px;" />
                                    <h5 style="margin-top: 5px;">{{ $t('waitlist.partial_arrived') }}</h5>
                                </div>
                                <div class="custom_big_btn blue_color1" :class="{'current': layer_user_info.all_arrived == 1}" @click="chooseArrive(1)">
                                    <TeamOutlined style="font-size: 28px;"/>
                                    <h5 style="margin-top: 5px;">{{ $t('waitlist.all_arrived') }}</h5>
                                </div>
                            </a-space>
                        </template>
                        <div class="custom_big_btn blue_color1" @click="doShowLayer('show_arrive_layer')">
                            <MinusCircleOutlined v-if="layer_user_info.all_arrived == 0" style="font-size: 28px;"/>
                            <UserOutlined v-if="layer_user_info.all_arrived == 2" style="font-size: 28px;"/>
                            <TeamOutlined v-if="layer_user_info.all_arrived == 1" style="font-size: 28px;"/>
                            <h5 style="margin-top: 5px;" v-if="layer_user_info.all_arrived == 0">{{ $t('waitlist.not_arrived') }}</h5>
                            <h5 style="margin-top: 5px;" v-if="layer_user_info.all_arrived == 2">{{ $t('waitlist.partial_arrived') }}</h5>
                            <h5 style="margin-top: 5px;" v-if="layer_user_info.all_arrived == 1">{{ $t('waitlist.all_arrived') }}</h5>
                        </div>
                    </a-popover>
                    <a-popover title="" trigger="click" placement="topRight" :open="show_color_tag_layer">
                        <template #content>
                            <div>
                                <a-flex wrap="wrap" gap="small" style="width: 320px;">
                                    <div class="custom_big_btn" @click="chooseColorTag()">
                                        <h5 style="margin-top: 20px;">{{ $t('common.default') }}</h5>
                                    </div>
                                    <div class="custom_big_btn" v-for="item in color_tag_list" v-bind:key="item.tag_id" @click="chooseColorTag(item)" :style="{backgroundColor: item.color}">
                                        <h5 style="margin-top: 20px;">{{ item.tname }}</h5>
                                    </div>
                                </a-flex>
                            </div>
                        </template>
                        <div class="custom_big_btn" @click="doShowLayer('show_color_tag_layer')">
                            <ClearOutlined style="font-size: 28px; color: #000;"/>
                            <h5 style="color: #000; margin-top: 5px;">{{ $t('common.color_tag') }}</h5>
                        </div>
                    </a-popover>
                </a-space>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { Modal } from 'ant-design-vue';
    import moment from 'moment-timezone';
    import dayjs from 'dayjs';

    import {
        Loading3QuartersOutlined,
        LoadingOutlined,
        HistoryOutlined,
        DeleteOutlined,
        AlertFilled,
        SafetyCertificateOutlined,
        MailOutlined,
        MessageFilled,
        CoffeeOutlined,
        PhoneOutlined,
        CommentOutlined,
        EyeInvisibleOutlined,
        MinusCircleOutlined,
        UserOutlined,
        TeamOutlined,
        ClearOutlined,
        FormOutlined,
        GlobalOutlined,
        GoogleOutlined,
        AuditOutlined,
        EditOutlined,
        CheckCircleOutlined,
    } from '@ant-design/icons-vue';

    export default {
        name: 'ReserveView',
        components: {
            Loading3QuartersOutlined,
            LoadingOutlined,
            HistoryOutlined,
            DeleteOutlined,
            AlertFilled,
            SafetyCertificateOutlined,
            MailOutlined,
            MessageFilled,
            CoffeeOutlined,
            PhoneOutlined,
            CommentOutlined,
            EyeInvisibleOutlined,
            MinusCircleOutlined,
            UserOutlined,
            TeamOutlined,
            ClearOutlined,
            FormOutlined,
            GlobalOutlined,
            GoogleOutlined,
            AuditOutlined,
            EditOutlined,
            CheckCircleOutlined,
        },
        data() {
            return {
                sid: this.$store.state.app.sid,
                sname: this.$store.state.app.sname,
                timezone: this.$store.state.app.timezone,
                is_mobile: this.$store.state.app.is_mobile,
                moment,

                show_arrive_layer: false,
                show_info_layer: false,
                show_color_tag_layer: false,
                layer_user_info: {},
                show_notify_layer: false,
                show_seat_layer: false,
                is_today: 0,

                value1: '',

                now_date_text: moment.tz(this.timezone).format('ddd, MMM DD'),
                now_date: moment.tz(this.timezone).format('YYYY-MM-DD'),
                now_date_number: moment.tz(this.timezone).date(),
                today_date_number: moment.tz(this.timezone).date(),
                today_month_number: moment.tz(this.timezone).month() + 1,
                choose_now_month: true,
                q: '',
                is_loading: false,
                is_sending: false,
                is_loading_timeline: false,
                reserve_list: [],
                timeline_list: [],
                color_tag_list: [],
                count_dict: {},

                seat_table_name: '',
                seat_real_tid: '',
                now_month_arr: [
                    { name: 'SUN', disabled: false, count: 0, month: '', date_num: '' },
                    { name: 'MON', disabled: false, count: 0, month: '', date_num: '' },
                    { name: 'TUE', disabled: false, count: 0, month: '', date_num: '' },
                    { name: 'WED', disabled: false, count: 0, month: '', date_num: '' },
                    { name: 'THU', disabled: false, count: 0, month: '', date_num: '' },
                    { name: 'FRI', disabled: false, count: 0, month: '', date_num: '' },
                    { name: 'SAT', disabled: false, count: 0, month: '', date_num: '' },
                ],
                now_month: moment.tz(this.timezone).format('MM月YYYY'),
                next_month_arr: [
                    { name: 'SUN', disabled: false, count: 0, month: '', date_num: '' },
                    { name: 'MON', disabled: false, count: 0, month: '', date_num: '' },
                    { name: 'TUE', disabled: false, count: 0, month: '', date_num: '' },
                    { name: 'WED', disabled: false, count: 0, month: '', date_num: '' },
                    { name: 'THU', disabled: false, count: 0, month: '', date_num: '' },
                    { name: 'FRI', disabled: false, count: 0, month: '', date_num: '' },
                    { name: 'SAT', disabled: false, count: 0, month: '', date_num: '' },
                ],
                next_month: moment.tz(this.timezone).add(1, 'M').format('MM月YYYY'),
                disabledDate: (current) => {
                    return current && current < moment.tz(this.timezone).subtract(1, 'days');
                },
            };
        },
        created() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
                return;
            }

            // 渲染当前月数据
            const daysInCurrentMonth = moment.tz(this.timezone).daysInMonth();
            const first_day = moment.tz(this.timezone).startOf('month').day();
            const first_month = moment.tz(this.timezone).format('MM');

            for (let i = 0; i < first_day; i++) {
                this.now_month_arr.push(
                    { name: ' ', disabled: false, count: 0, month: first_month, date_str: '' },
                );
            }

            for (let i = 1; i <= daysInCurrentMonth; i++) {
                this.now_month_arr.push(
                    { name: i, disabled: i < this.today_date_number ? true : false, count: 0, month: first_month, date_str: moment().tz(this.timezone).date(i).format('YYYY-MM-DD') }
                );
            }

            // 渲染下一月数据
            const daysInNextMonth = moment.tz(this.timezone).add(1, 'M').daysInMonth();
            const next_first_day = moment.tz(this.timezone).add(1, 'M').startOf('month').day();
            const next_month = moment.tz(this.timezone).add(1, 'M').format('MM');

            for (let i = 0; i < next_first_day; i++) {
                this.next_month_arr.push(
                    { name: ' ', disabled: false, count: 0, month: first_month, date_str: ' ' },
                );
            }

            for (let i = 1; i <= daysInNextMonth; i++) {
                this.next_month_arr.push(
                    { name: i, disabled: false, count: 0, month: next_month, date_str: moment().tz(this.timezone).add(1, 'M').date(i).format('YYYY-MM-DD') }
                );
            }
        },
        mounted() {
            this.get_color_tags();
            this.get_future_count();
            this.get_list();
        },
        beforeUnmount() {
            clearInterval(this.list_timer);
        },
        computed: {
            ...mapGetters({
            })
        },
        methods: {
            ...mapActions({
                do_get_request: 'waitlist/do_get_request',
            }),
            onSearch() {
                this.get_list();
            },
            doHideInfoLayer() {
                this.show_arrive_layer = false;
                this.show_info_layer = false;
                this.show_color_tag_layer = false;
                this.layer_user_info = {};
                this.show_notify_layer = false;
            },
            async get_list() {
                this.is_loading = true;
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    rstatus: '0,1,6',
                    q: this.q,
                    date: this.now_date,
                    api: '/weapp/admin/v4/reservation/list'
                });
                let today_date = moment.tz(this.timezone).format('YYYY-MM-DD');
                let now_hours = moment.tz(this.timezone).hours();

                if (now_hours < 5) {
                    today_date = moment.tz(new Date().getTime() - 1 * 24 * 3600 * 1000, this.timezone).format('YYYY-MM-DD');
                }

                if (today_date == this.now_date) {
                    this.is_today = 1;
                } else {
                    this.is_today = 0;
                }

                this.reserve_list = res_data.data.map(item => {
                    item.phone_num_short = item.phone_num ? item.phone_num.substr(-4) : '';
                    item.reserve_time_str = moment.tz(item.reservation_timestamp * 1, this.timezone).format('HH:mm A');
                    item.reserve_date_str = moment.tz(item.reservation_timestamp * 1, this.timezone).format('MM/DD');

                    if (item.last_sms_timestamp) {
                        item.ready_text_time = moment(item.last_sms_timestamp * 1).fromNow().replace('ago', '').replace('an hour ', '1h').replace('a minute ', '1m').replace('a few seconds ', '1m').replace('minutes', 'm').replace('hours','h');
                    } else {
                        item.ready_text_time = '';
                    }

                    return item;
                });

                this.is_loading = false;
            },
            async do_checkin(rid) {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    rid: rid,
                    api: '/weapp/admin/v2/reservation/checkin'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    
                    this.onSearch();
                    this.doHideInfoLayer();
                }
            },
            async do_seat() {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    r_tname: this.seat_table_name,
                    real_tid: this.seat_real_tid,
                    rid: this.layer_user_info.rid,
                    api: '/weapp/admin/v2/reservation/seat'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.show_seat_layer = false;
                    
                    this.onSearch();
                    this.doHideInfoLayer();
                }
            },
            doCheckIn(item) {
                if (item.rstatus == 6) {
                    return;
                }

                let that = this;
                Modal.confirm({
                    title: this.$t('waitlist.check_in') + `${item.customer_name}(${item.people_num})?`,
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    maskClosable: true,
                    onOk() {
                        that.do_checkin(item.rid);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },
            undoCheckIn(item) {
                let that = this;

                Modal.confirm({
                    title: this.$t('waitlist.unchecked_in') + ` ${item.customer_name}(${item.people_num})?`,
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    maskClosable: true,
                    onOk() {
                        that.undo_checkin(item.rid);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },
            async undo_checkin(rid) {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    rid: rid,
                    api: '/weapp/admin/reservation/back_to_waiting',
                    method: 'post'
                });
                
                if (res_data.code == 100000) {
                    this.onSearch();
                    this.$message.success(this.$t('common.success'));
                    this.show_notify_layer = false;
                    this.doHideInfoLayer();
                }
            },
            doShowNotifyLayer(item) {
                this.show_notify_layer = true;

                if (item) {
                    this.layer_user_info = item;
                }
            },
            async do_send_notification(type) {
                this.is_sending = true;

                if (type == 'one_way_sms_remind') {
                    await this.do_send_one_way_sms_remind();
                } else if (type == 'one_way_voice_remind') {
                    await this.do_send_one_way_voice_remind();
                }

                this.is_sending = false;
            },
            async do_send_one_way_sms_remind() {
                if (!this.layer_user_info.rid) {
                    return;
                }
                
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    rid: this.layer_user_info.rid,
                    api: '/weapp/admin/reservation/ready',
                    method: 'get'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.show_notify_layer = false;
                    this.doHideInfoLayer();
                }
            },
            async do_send_one_way_voice_remind() {
                if (!this.layer_user_info.rid) {
                    return;
                }
                
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    rid: this.layer_user_info.rid,
                    api: '/weapp/admin/reservation/ready_voice',
                    method: 'get'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.show_notify_layer = false;
                    this.doHideInfoLayer();
                }
            },
            doShowSeatLayer(item) {
                this.show_seat_layer = true;

                if (item) {
                    this.layer_user_info = item;
                }
            },
            doShowInfoLayer(item) {
                this.show_info_layer = true;
                this.layer_user_info = item;

                this.get_timeline();
                this.get_guest_tag();
            },
            async get_timeline() {
                this.is_loading_timeline = true;
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    type: 'reservation',
                    id: this.layer_user_info.rid,
                    api: '/weapp/admin/message/get'
                });

                let message_status = [this.$t('common.sending'), this.$t('common.send_success'), this.$t('common.send_error')];

                let data_list = [];
                if (res_data.code == 100000) {
                    let notification_list = res_data.data.list;

                    data_list.push({
                        title: moment.tz(this.layer_user_info.create_timestamp * 1, this.timezone).format('HH:mm A') + ' ' + this.$t('reserve.create_reservation'),
                        detail: '',
                        timestamp: this.layer_user_info.create_timestamp
                    });

                    notification_list.map(item => {
                        data_list.push({
                            title: moment.tz(item.create_timestamp * 1, this.timezone).format('HH:mm A') + ' ' + this.$t(`common.${item.type}`) + '(' + message_status[item.status] + ')',
                            detail: item.content,
                            timestamp: item.create_timestamp
                        });
                    });

                    if (this.layer_user_info.checkedin_timestamp) {
                        data_list.push({
                            title: moment.tz(this.layer_user_info.checkedin_timestamp * 1, this.timezone).format('HH:mm A') + ' ' + this.$t('waitlist.checked_in'),
                            detail: '',
                            timestamp: this.layer_user_info.checkedin_timestamp
                        });
                    }

                    if (this.layer_user_info.arrived_timestamp > 0) {
                        data_list.push({
                            title: moment.tz(this.layer_user_info.arrived_timestamp * 1, this.timezone).format('HH:mm A') + ' ' + (this.layer_user_info.all_arrived == 1 ? this.$t('waitlist.all_arrived') : this.$t('waitlist.partial_arrived')),
                            detail: '',
                            timestamp: this.layer_user_info.arrived_timestamp
                        });
                    }

                    data_list = data_list.sort((a, b) => a.timestamp - b.timestamp);

                    this.timeline_list = data_list;
                    this.is_loading_timeline = false;
                }
            },
            async get_guest_tag() {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    phone_num_list: this.layer_user_info.phone_num,
                    api: '/weapp/admin/guests/tag/get',
                    method: 'post'
                });

                if (res_data.code == 100000) {
                    let guest_info = res_data.data.length > 0 ? res_data.data[0] : {};
                    let new_tags_arr = [];
                    new_tags_arr.push({
                        name: (guest_info.wait_count + guest_info.reservation_count) + ' ' + this.$t('common.visits'),
                        color: '#87d068'
                    });

                    new_tags_arr.push({
                        name: guest_info.missed_count + ' ' + this.$t('waitlist.no_show'),
                        color: 'red'
                    });

                    guest_info.tags.map(item => {
                        new_tags_arr.push({
                            name: '!' + item.tname,
                            color: 'red'
                        });
                    });

                    this.layer_user_info.guest_tags = new_tags_arr;
                }
            },
            async doEditRemark(item) {
                if (!item.rid) {
                    return;
                }

                let res_data = await this.do_get_request({
                    sid: this.sid,
                    rid: item.rid,
                    remark: encodeURIComponent(item.remark),
                    api: '/weapp/admin/reservation/update_remark',
                    method: 'get'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.doHideInfoLayer();
                }
            },
            doCancel(item) {
                if (!item.rid) {
                    return;
                }

                let that = this;
                Modal.confirm({
                    title: this.$t('reserve.cancel') + ` ${item.customer_name}(${item.people_num})?`,
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    maskClosable: true,
                    onOk() {
                        that.do_cancel(item.rid);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },
            async do_cancel(rid) {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    rid: rid,
                    leave_reason: '',
                    api: '/weapp/admin/reservation/cancel_with_reason',
                    method: 'post'
                });
                
                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.doHideInfoLayer();
                }
            },
            doShowLayer(layer) {
                this[layer] = this[layer] ? false : true;
            },
            async chooseColorTag(color_item) {
                this.show_color_tag_layer = false;

                let res_data = await this.do_get_request({
                    sid: this.sid,
                    rid: this.layer_user_info.rid,
                    color_tag_list: color_item ? [color_item] : [],
                    api: '/weapp/admin/reservation/update_color_tag',
                    method: 'post'
                });

                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.layer_user_info.color_tags = [color_item];
                }

                this.show_arrive_layer = false;
            },
            async chooseArrive(arrive) {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    rid: this.layer_user_info.rid,
                    all_arrived: arrive,
                    api: '/weapp/admin/reservation/all_arrived/set',
                    method: 'post'
                });

                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.layer_user_info.all_arrived = arrive;
                }

                this.show_arrive_layer = false;
            },
            async get_color_tags() {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    status: 'active',
                    service: 'reservation',
                    api: '/weapp/admin/colortags/list'
                });
                
                if (res_data.code == 100000) {
                    let tag_list = res_data.data;
                    this.color_tag_list = tag_list;
                }
            },
            chooseDate(month, date, flag) {
                if (flag && date < this.today_date_number) {
                    return;
                }

                // this.value1 = moment.tz(month + '-' + date, 'MM月YYYY-D', this.timezone);
                this.value1 = dayjs(month + '-' + date, 'MM月YYYY-D');

                this.now_date_number = date;
                this.choose_now_month = flag;
                this.now_date = moment.tz(month + '-' + date, 'MM月YYYY-D', this.timezone).format('YYYY-MM-DD');
                this.now_date_text = moment.tz(month + '-' + date, 'MM月YYYY-D', this.timezone).format('ddd, MMM DD');
                this.get_list();
            },
            async get_future_count() {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    type: 'all',
                    api: '/weapp/admin/v2/reservation/future/get_daily_count'
                });

                if (res_data.code == 100000) {
                    this.count_dict = res_data.data.data_dict;
                }
            },
            onDateChange(val) {
                this.now_date_number = val.date();
                this.choose_now_month = (val.month() + 1) == this.today_month_number ? true : false;
                this.now_date = val.format('YYYY-MM-DD');
                this.now_date_text = val.format('ddd, MMM DD');
                // this.now_date_number = val;
                this.get_list();
            },
            doConfirm(item) {
                let that = this;
                Modal.confirm({
                    title: this.$t('common.confirm') + ` ${item.customer_name}(${item.people_num}) ${this.$t('reserve.this_reservation')}?`,
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    maskClosable: true,
                    onOk() {
                        that.do_confirm(item.rid);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },
            async do_confirm(rid) {
                let res_data = await this.do_get_request({
                    sid: this.sid,
                    rid: rid,
                    api: '/weapp/admin/reservation/confirm',
                });

                if (res_data.code == 100000) {
                    this.$message.success(this.$t('common.success'));
                    this.get_list();
                }
            }
        }
    }
</script>

<style scoped>
    .custom_big_btn {
        display: inline-block;
        height: 75px;
        width: 100px;
        border-radius: 5px;
        border: 1px solid #d7cdcd;
        text-align: center;
        padding: 5px;
        cursor: pointer;
        background-color: #fff;
    }

    .custom_big_btn .list_icon {
        font-size: 38px;
    }
    .user_info_zone .ant-drawer-body{
        overflow: hidden;
        padding: 10px 24px;
    }
    .user_info_zone .ant-divider {
        margin: 0 0 10px;
    }
    .user_waitlist_data, .user_timeline {
        margin-top: 10px;
    }
    .user_info_header {
        max-height: 100px;
        overflow: auto;
    }
    .user_info_zone .user_top_action .custom_big_btn {
        width: 115px;
        height: 62px;
    }
    .user_info_zone .user_bottom_action .custom_big_btn {
        width: 115px;
        height: 62px;
    }
    .user_timeline .ant-timeline .ant-timeline-item {
        padding-bottom: 7px;
    }
    .green_color1{
        color: #fff; background-color: #5f9562; border-color: #5f9562;
    }
    .yellow_color1{
        color: #fff;
        background-color: #f9bd58;
        border-color: #f9bd58;
    }
    .yellow_color2{
        color: #fff; background-color: #e78156; border-color: #e78156;
    }
    .blue_color1{
        color: rgb(48, 48, 240); border-color: rgb(48, 48, 240);
    }
    .grey_color {
        color: #fff;
        background-color: #625f5f;
        border-color:#625f5f;
    }
    .blue_color1.current {
        background-color: rgb(48, 48, 240);
        width: 120px; color: #fff;
    }

    .calendar_zone {
        padding: 10px;
        background-color: #fff;
        border-radius: 10px;
        height: 88vh;
        overflow: auto;
    }
    .calendar_zone h3 {
        padding-left: 7px;
    }
    .calendar_zone .item {
        width: 14%;
        height: 35px;
        margin-bottom: 10px;
        text-align: center;
        padding-top: 5px;
        cursor: pointer;
    }
    .calendar_zone .item.current {
        border: 1px solid #000;
    }
    .calendar_zone .item.disabled {
        color: #b8aeae;
    }
    .calendar_zone .item .num {
        font-size: 10px;
        background: red;
        width: 15px;
        margin: 0 auto;
        color: #fff;
        border-radius: 5px;
    }

    .right_zone {
        padding: 0 10px;
    }

    @media screen and (max-width: 768px) {
        .left_zone {
            display: none;
        }
        .right_zone {
            padding: 0 5px;
        }
        .ant-list-item {
            padding: .3rem .5rem;
        }
        .ant-list-item .custom_big_btn {
            width: 50px;
            padding: 2px;
            font-size: 12px;
        }
        .ant-list-item .custom_big_btn .list_icon {
            font-size: 24px;
        }
        .ant-list-item .custom_big_btn h5 {
            white-space: nowrap;
            overflow: hidden;
        }
        .user_info_zone .ant-drawer-body {
            padding: .3rem .5rem;
        }
    }
</style>